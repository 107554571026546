:root {
  --borderRadius: 12px;
  --formBackgrounColor: #cfcfcf;
}

.App {
  /*text-align: center;*/
  /*background: linear-gradient(45deg, #56ff80, #5fc19c, #96ffb0, #21ffc8, #55ff12);*/
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (prefers-reduced-motion: no-preference) {
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.insideContent {
  /* position: fixed;
  overflow-y: auto;
  top: 5rem;
  bottom: 7rem; */
}

.mainPage {
  margin-top: 5%;
  padding-right: 5%;
  padding-top: 4rem;

  position: fixed;
  overflow-y: auto;
  top: 0rem;
  bottom: 10%;
}

.subPage {
  padding: 2rem;
  background: var(--formBackgrounColor);
  border-radius: var(--borderRadius);
  margin: 1rem;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0px;
}

li {
  line-height: 2rem;
}
